<template lang="pug">
div
  router-view(
    :table-filters.sync="tableFilters"
  )
</template>
<script>
export default {
  name: 'Orders',
  data() {
    return {
      tableFilters: {},
    }
  },
}
</script>
